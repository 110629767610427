function setItem(key, value, expiryInMinutes) {
    const data = {
        value: value,
        expiry: new Date().getTime() + expiryInMinutes * 60 * 1000
    };
    localStorage.setItem(key, JSON.stringify(data));
}

function getItem(key) {
    const data = JSON.parse(localStorage.getItem(key));
    if (data && new Date().getTime() < data.expiry) {
        return data.value;
    } else {
        localStorage.removeItem(key);  // 数据过期，删除
        return null;
    }
}

function clearItem(key) {
    localStorage.removeItem(key);
}

export {setItem, getItem, clearItem};