import './ErrorPage.less'

function ErrorPage() {

    return (
        <div className="error-page">
            Hello Error !
        </div>
    );
}

export default ErrorPage;