import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import "./index.css";
import App from "./App"
import Appoint from "./pages/Appoint/Appoint";
import Orders from "./pages/Order/Orders";
import Profile from "./pages/Profile/Profile";
import Login from "./pages/Login/Login";
import Charge from "./pages/Charge/Charge";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import MyCoupon from "./pages/MyCoupon/MyCoupon";
import OrderDetail from "./pages/OrderDetail/OrderDetail";
import Book from "./pages/Book/Book";
import BookResult from '@/pages/BookResult';

import './main.css'
import store from './store';

import './axiosSetup'

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>
    },
    {
        path: "/appoint",
        element: <Appoint />
    },
    {
        path: "/orders",
        element: <Orders />
    },
    {
        path: "/profile",
        element: <Profile />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/charge",
        element: <Charge />
    },
    {
        path: "/error",
        element: <ErrorPage />
    },
    {
        path: "/myCoupon",
        element: <MyCoupon />
    },
    {
        path: "/order-detail",
        element: <OrderDetail />
    },
    {
        path: "/book",
        element: <Book />
    },
    {
        path: "/book-result",
        element: <BookResult />
    }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <Provider store={store}>
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    </Provider>
);
