import {useLocation} from 'react-router-dom'
import {Helmet} from "react-helmet";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Toast} from "antd-mobile";
import {connect} from 'react-redux';

import PayPop from "@/components/PayPop";
import SelectProject from "@/components/SelectProject";

const Book = ({bookUserList, bookTime, setBookUserList, setBookTime}) => {
    const state = useLocation().state;
    const [bookUserCount, setBookUserCount] = React.useState(1);
    const [showTime, setShowTime] = React.useState(false);
    const [showProject, setShowProject] = React.useState(false);
    const [bookStockDay, setBookStockDay] = React.useState([]);
    const [bookStockTime, setBookStockTime] = React.useState([]);
    const [amCheck, setAmCheck] = React.useState(true);
    const [totalPrice, setTotalPrice] = React.useState(0);
    const [bookInfo, setBookInfo] = useState({});
    const [selectBookUser, setSelectBookUser] = React.useState(null);
    const [showPayPop, setShowPayPop] = React.useState(false);
    const [userRemark, setUserRemark] = React.useState(null);

    useEffect(() => {
        calTotalPrice()
    }, []);

    const plusBookUser = () => {
        setBookUserCount(bookUserCount + 1);
        setBookUserList([...bookUserList, {
            projects: []
        }]);
    }

    const minusBookUser = () => {
        if (bookUserCount > 1) {
            setBookUserCount(bookUserCount - 1);
            setBookUserList(bookUserList.slice(0, -1))
        }
    }

    // 去支付
    const goPay = () => {
        console.log(bookUserList);
        for (let i = 0; i < bookUserList.length; i++) {
            if (bookUserList[i].projects.length === 0) {
                Toast.show({
                    content: '预约人' + (i + 1) + '必须选一个项目',
                    maskStyle: {zIndex: 1300}
                });
                return;
            }
        }

        if (bookTime === '') {
            Toast.show({
                content: '预约时间未选择',
                maskStyle: {zIndex: 1300}
            });
            return;
        }

        let data = {
            openId: localStorage.getItem("wx-open-id"),
            bookTime: bookTime,
            shopId: state.shopId,
            userRemark: userRemark,
            guestInfoList: [...bookUserList]
        }
        axios.post('/api/order/book', data)
            .then(payParams => {
                // 处理成功的响应
                window.WeixinJSBridge.invoke('getBrandWCPayRequest', payParams,
                    function (res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            // 使用以上方式判断前端返回,微信团队郑重提示：
                            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                            setShowPayPop(true);
                        }
                    });
            })
            .catch(error => {
                // 处理错误
                console.error(error);
            });

    }

    const onUserRemarkChange = (e) => {
        setUserRemark(e.target.value)
    }

    const calTotalPrice = () => {
        let tempTotalPrice = 0;
        for (let i = 0; i < bookUserList.length; i++) {
            bookUserList[i].projects.forEach((entry) => {
                tempTotalPrice = tempTotalPrice + entry.sellPrice;
            });
        }
        setTotalPrice(tempTotalPrice);
    }

    const selectProjectCallBack = (selectProducts, selectMechs) => {
        selectBookUser.projects = selectProducts;
        setBookUserList([...bookUserList]);
        calTotalPrice();
    }

    const fetchShopBookInfo = async (shopId) => {
        let response = await axios.get(`/api/shop/bookInfo/${shopId}`);
        setBookInfo(response);
        setShowProject(true);
    }

    const fetchShopTime = async (shopId) => {
        const response = await axios.get(`/api/shop/stock-time/${shopId}`);
        response.forEach(item => item.selected = false);
        response[0].selected = true;
        setBookStockTime(response[0].amStockTimes)
        setBookStockDay(response);
        setShowTime(true);
    }

    const chooseBookTime = () => {
        fetchShopTime(state.shopId);
    }

    const closeTimeDrawer = () => {
        setShowTime(false);
    }

    const showProjectPick = (bookUser) => {
        fetchShopBookInfo(state.shopId);
        setSelectBookUser(bookUser);
    }

    const chooseStockDay = (stockDay) => {
        bookStockDay.forEach((item) => {
            item.selected = stockDay.day === item.day;
        });
        setBookStockDay([...bookStockDay]);
        chooseAm();
    }

    const chooseAm = () => {
        setAmCheck(true);
        bookStockDay.forEach(item => {
            if (item.selected) {
                item.amStockTimes.forEach(itemTime => itemTime.selected = false);
                setBookStockTime(item.amStockTimes)
            }
        })
    }

    const choosePm = () => {
        setAmCheck(false);
        bookStockDay.forEach(item => {
            if (item.selected) {
                item.pmStockTimes.forEach(itemTime => itemTime.selected = false);
                setBookStockTime(item.pmStockTimes);
            }
        })
    }

    const clickStockTime = (stockTime) => {
        bookStockTime.forEach(item => item.selected = item.time === stockTime.time);
        setBookStockTime([...bookStockTime]);
    }

    const sureStockTime = () => {
        bookStockDay.forEach(stockDay => {
            if (stockDay.selected) {
                bookStockTime.forEach(stockTime => {
                    if (stockTime.selected) {
                        setBookTime(stockDay.formatDay + ' ' + stockTime.time);
                        closeTimeDrawer()
                    }
                })
            }
        })
    }

    return (
        <div>
            <Helmet>
                <title>预定</title>
            </Helmet>
            <div id="appview">
                <div data-reactroot="" id="g_body">
                    <div className="book-wrap">
                        <div className="book-content-wrap">
                            <div className="book-section">
                                <div className="line-list book-list">
                                    <div className="row">
                                        <div className="title">预约人数</div>
                                        <div className="content">
                                            <div className="number-counter">
                                                <div className="left disable" onClick={() => minusBookUser()}>-</div>
                                                <div className="number-box">{bookUserCount}</div>
                                                <div className="right" onClick={() => plusBookUser()}>+</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="book-section">
                                {bookUserList.map((bookUser, index) => (
                                    <div className="line-list book-item-list" key={index}>
                                        <div className="row">
                                            <div className="icon-box">
                                                <div className="icon-people"></div>
                                            </div>
                                            <div className="middle-box">
                                                <div className="top">
                                                    <div className="txt-blue">预约人{index + 1}</div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="item-list">
                                                        {bookUser.projects.map((projectItem) => (
                                                            <div className="item-row">
                                                                <div className="item-name">{projectItem.name}
                                                                </div>
                                                                <div className="price-box"><span
                                                                    className="txt-red">¥{projectItem.sellPrice}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="side-box" onClick={() => showProjectPick(bookUser)}>
                                                <div className="btn-blue-circle">选择项目</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="book-section" onClick={() => chooseBookTime()}>
                                <div className="line-list book-list">
                                    <div className="row hasarrow time-box">
                                        <div className="title">到店时间:</div>
                                        <div className="content">{bookTime}</div>
                                        <div className="icon-arrow-right"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="book-section">
                                <div className="line-list book-list">
                                    <div className="row">
                                        <div className="title">支付方式:</div>
                                        <div className="content">
                                            <div className="radio-list">
                                                <div className="radio-item">
                                                    <span className="icon-radio-checked"></span>
                                                    <span className="radio-txt">微信支付</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{display: 'none'}}>
                                        <div className="title">称呼:</div>
                                        <div className="content">
                                            <div className="name-wrap">
                                                <div className="name-box">
                                                    <input
                                                        type="text"
                                                        placeholder="请输入您的姓名"
                                                        value="莫"/>
                                                </div>
                                                <div className="gender-selector">
                                                    <div className="btn-blue-circle">先生</div>
                                                    <div className="btn-blue-circle disable">女士</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row remark">
                                        <div className="title">备注:</div>
                                        <div className="content">
                                            <textarea
                                                type="text" maxlength="30"
                                                onChange={onUserRemarkChange}
                                                placeholder="可将您的其他要求告诉商家">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-footer">
                            <div className="pay-bar">
                                <div className="price-info">
                                    <div className="txt-red">
                                        <span>合计:</span><strong>¥{totalPrice}</strong>
                                    </div>
                                </div>
                                <div className="btn-red" onClick={() => goPay()}>去支付</div>
                            </div>
                        </div>
                        <div className="pop-wrap" style={{display: showTime ? 'block' : 'none'}}>
                            <div className="g-mask" onClick={() => closeTimeDrawer()}></div>
                            <div className="pop-drawer">
                                <div className="pop-title">
                                    <div className="name-box"><span className="pre"></span><span
                                        className="name">到店时间</span><span className="after"></span></div>
                                    <div className="btn-close" onClick={() => closeTimeDrawer()}></div>
                                </div>
                                <div className="pop-content">
                                    <div className="picker-box">
                                        <div className="x-scroll-list day-picker">
                                            {bookStockDay.map((item, index) => (
                                                <div
                                                    className={`item ${item.selected ? 'current' : ''}`}
                                                    onClick={() => chooseStockDay(item)}
                                                >
                                                    <p>{item.week}</p>
                                                    <p>{item.day}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="time-wrap js_times_container">
                                            <div className="apm-tab">
                                                <div
                                                    className={`item ${amCheck ? 'current' : ''}`}
                                                    onClick={() => chooseAm()}
                                                >
                                                    <span className="icon-day"></span>
                                                    <span className="time">10:00-17:00</span>
                                                </div>
                                                <div
                                                    className={`item ${!amCheck ? 'current' : ''}`}
                                                    onClick={() => choosePm()}
                                                >
                                                    <span className="icon-night"></span>
                                                    <span className="time">17:15-23:00</span>
                                                </div>
                                            </div>
                                            <div className="time-picker">
                                                {bookStockTime.map((item, index) => (
                                                    <div
                                                        className={`item ${item.selected ? 'current' : ''}`}
                                                        onClick={() => clickStockTime(item)}
                                                    >
                                                        <div className="item-box">
                                                            <div className="center-box">
                                                                <p>{item.time}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pop-footer" onClick={() => sureStockTime()}>
                                    <div className="pop-btn-footer-box">
                                        <div className="btn-red">确定</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showProject ?
                            <SelectProject
                                shopId={state.shopId}
                                bookInfo={bookInfo}
                                setShowDrawer={setShowProject}
                                next={selectProjectCallBack}/> : ''}
                        {showPayPop ? <PayPop setShowPayPop={setShowPayPop}/> : ''}
                    </div>
                </div>
            </div>
        </div>
    );
}

// 将 Redux 状态映射到组件的 props
const mapStateToProps = state => ({
    bookUserList: state.bookUserList,
    bookTime: state.bookTime
});

const setBookUserList = (payload) => ({
    type: 'setBookUserList',
    payload: payload
})

const setBookTime = (payload) => ({
    type: 'setBookTime',
    payload: payload
})

// 将动作映射到组件的 props
const mapDispatchToProps = {
    setBookUserList,
    setBookTime
};


// 连接组件和 Redux
export default connect(mapStateToProps, mapDispatchToProps)(Book);