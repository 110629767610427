import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {connect} from 'react-redux';

import StoreList from '@/components/StoreList/StoreList';
import SelectProject from '@/components/SelectProject';

import { getItem } from '@/localstore';
import { X_TOKEN, WX_OPEM_ID } from "../../constants";

import './Appoint.css';

function Appointment({setBookUserList}) {
    const [stores, setStores] = useState([]);
    const [showDrawer, setShowDrawer] = useState(false);
    const [selectShopId, setSelectShopId] = useState(-1);
    const [bookInfo, setBookInfo] = useState({});
    const navigate = useNavigate();

    const isWeChatClient = () =>  {
        let ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('micromessenger') > -1;
    }

    useEffect(() => {

        // 不是微信
        if (!isWeChatClient()) {
            fetchShops();
            return;
        }

        // 判断localstorage有没有存openid
        if (localStorage.getItem(WX_OPEM_ID)) {
            initGetShops();
            return;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const paramsObj = {};
        for (const [key, value] of urlParams.entries()) {
            paramsObj[key] = value;
        }
        console.log(paramsObj);
        if (!paramsObj.code) {
            // 没有code
            let encodedUrl = encodeURIComponent(window.location.href);
            console.log(encodedUrl);
            let redirectURL = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx096139dc4ea52367&redirect_uri=" + encodedUrl + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
            window.location.href = redirectURL;
        } else {
            // 有code， 去拿openid
            getOpIdByApi(paramsObj.code);
        }

    }, []);

    const initGetShops = () => {
        axios.get('/api/wx-config?url=' + window.location.href)  // 替换为你的API地址
            .then(response => {
                window.wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: response.appId, // 必填，公众号的唯一标识
                    timestamp: response.timestamp, // 必填，生成签名的时间戳
                    nonceStr: response.nonceStr, // 必填，生成签名的随机串
                    signature: response.signature,// 必填，签名
                    jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
                });

                window.wx.ready(function () {
                    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。

                    window.wx.getLocation({
                        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                        success: function (res) {
                            let latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                            let longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                            let speed = res.speed; // 速度，以米/每秒计
                            let accuracy = res.accuracy; // 位置精度
                            fetchShops(latitude, longitude);
                        },
                        fail: function (err) {
                            fetchShops();
                        }
                    });
                });

            })
            .catch(error => {
                console.error('Error fetching stores:', error);
            });
    }

    const fetchShops = async (latitude, longitude) => {
        let url = "/api/shop/list?";
        if (latitude && longitude) {
            url += "&latitude=" + latitude + "&longitude=" + longitude;
        }
        let shopList = await axios.get(url);
        setStores(shopList);
    }

    const openDrawer = (shopId) => {
        setSelectShopId(shopId);
        fetchShopBookInfo(shopId);
    };

    const getOpIdByApi = async (code) => {
        let openId = await axios.get(`/api/get-openid?code=${code}`);
        localStorage.setItem(WX_OPEM_ID, openId);
        initGetShops();
    }

    const fetchShopBookInfo = async (shopId) => {
        let data = await axios.get(`/api/shop/bookInfo/${shopId}`);
        setBookInfo(data);
        setShowDrawer(true);
    }

    const goBook = (projects, mechs) => {
        setBookUserList([{
            projects: projects
        }]);

        // 判断有没有登录
        if (!getItem(X_TOKEN)) {
            navigate('/login?redirectURL=' + window.location.href);
            return;
        }
        navigate('/book',
            {
                state: {shopId: selectShopId}
            });
    }

    return (
        <div className="appointment">
            <Helmet>
                <title>预约</title>
            </Helmet>
            <div id="appview">
                <div data-reactroot="" id="g_body">
                    <div className="index-wrap">
                        <div className="index-list">
                            <div className="js_list_container">
                                <div className="shop-list-box">
                                    <StoreList stores={stores} openDrawer={openDrawer}/>
                                </div>
                            </div>
                        </div>
                        {showDrawer ? <SelectProject shopId={selectShopId}
                                                     bookInfo={bookInfo}
                                                     setShowDrawer={setShowDrawer}
                                                     next={goBook} /> : ''}
                    </div>
                </div>
            </div>
        </div>
    );
}

// 将 Redux 状态映射到组件的 props
const mapStateToProps = state => ({
    bookUserList: state.bookUserList
});

const setBookUserList = (payload) => ({
    type: 'setBookUserList',
    payload: payload
})

// 将动作映射到组件的 props
const mapDispatchToProps = {
    setBookUserList
};

// 连接组件和 Redux
export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
