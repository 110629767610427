import React from 'react';
import './Login.scss';
import user_logo from '../../static/img/user_logo.png';
import user_user from '../../static/img/user_user.png'
import user_password_icon from '../../static/img/user_password.png'
import {Helmet} from "react-helmet";
import axios from "axios";
import {Toast} from "antd-mobile";
import {setItem} from "../../localstore";
import {X_TOKEN} from "../../constants";

function Login() {

    const [phone, setPhone] = React.useState('');
    const [userCode, setUserCode] = React.useState('');
    const [btnText, setBtnText] = React.useState('获取验证码');
    const queryParams = new URLSearchParams(window.location.search);

    let intervalId = '';
    let btnInt = 60;

    const onPhoneChange = (e) => {
        setPhone(e.target.value);
    }

    const onUserCode = (e) => {
        setUserCode(e.target.value);
    }

    const login = () => {
        loginByApi(phone, userCode);
    }

    const loginByApi = async (phone, userCode) => {

        if (!phone) {
            Toast.show({
                content: '请输入手机号',
                maskStyle: {zIndex: 1300}
            });
            return;
        }

        if (!userCode) {
            Toast.show({
                content: '请输入验证码',
                maskStyle: {zIndex: 1300}
            });
            return;
        }

        let data = {
            mobile: phone,
            smsCode: userCode,
        }
        let token = await axios.post('/api/login', data);
        console.log(token);
        Toast.show({
            content: '登录成功',
            maskStyle: {zIndex: 1300}
        });
        setItem(X_TOKEN, token, 60 * 24 * 30);
        let redirectURL = queryParams.get('redirectURL');
        if (redirectURL) {
            window.location.href = redirectURL;
        }
    }

    const sendSms = async () => {

        if (btnText === '获取验证码') {
            if (!phone) {
                Toast.show({
                    content: '请输入手机号',
                    maskStyle: {zIndex: 1300}
                });
                return;
            }

            await axios.post('/api/sendSms', {
                mobile: phone,
            });

            Toast.show({
                content: '发送成功',
                maskStyle: {zIndex: 1300}
            });

            intervalId = setInterval(() => {

                if (btnInt < 0) {
                    clearInterval(intervalId);
                    setBtnText('获取验证码')
                    btnInt = 60;
                } else {
                    setBtnText(btnInt--);
                }
            }, 1000)
        }

    }

    return (
        <div>
            <section className="half"></section>
            <section className="login">
                <Helmet>
                    <title>登录</title>
                </Helmet>
                <div className="logo">
                    <img src="https://shenghe-static.oss-cn-shanghai.aliyuncs.com/shenghe_shop.jpg" alt=""/>
                </div>
                <h3>圣和养生</h3>
                <div className="form" action="">
                    <div className="user_phone">
                        <div className="phone">
                            <label htmlFor="phone">
                                <img src={user_user} alt=""/>
                            </label>
                            <input type="text" onChange={onPhoneChange} placeholder="请输入手机号"/>
                        </div>
                    </div>
                    <div className="user_code">
                        <div className="code">
                            <label htmlFor="id_code">
                                <img src={user_password_icon} alt=""/>
                            </label>
                            <input type="text" onChange={onUserCode} placeholder="请输入验证码"/>
                            <button type="button" className="code_btn" onClick={() => sendSms()}>
                                {btnText}
                            </button>
                        </div>
                    </div>
                    <button type="button" className="sub_btn" onClick={() => login()}>登 录</button>
                </div>
            </section>
        </div>
    );
}

export default Login;
