import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import OrderItem from '@/components/OrderItem';
import './Orders.css';
import {Helmet} from "react-helmet";
import { getItem } from "@/localstore";
import {useNavigate} from "react-router-dom";

function Orders() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false); // 初始值为 false
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const loader = useRef(null);
    const isFetching = useRef(false); // 引用，用来阻止重复请求
    const navigate = useNavigate();

    const fetchOrders = async (page) => {
        if (isFetching.current) return; // 如果正在加载，直接返回

        isFetching.current = true; // 标记为正在加载
        setLoading(true);

        try {
            const response = await axios.get(`/api/order/list?page=${page}`);
            setOrders(prevOrders => [...prevOrders, ...response.records]);
            setTotalPages(response.pages);
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
            isFetching.current = false; // 标记为加载完成
        }
    };

    useEffect(() => {

        if (!getItem('X-TOKEN')) {
            navigate('/login?redirectURL=' + window.location.href);
            return;
        }
        fetchOrders(currentPage);
    }, [currentPage]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '20px',
            threshold: 1.0
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && currentPage < totalPages && !loading) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        }, options);

        if (loader.current) {
            observer.observe(loader.current);
        }

        return () => {
            if (loader.current) {
                observer.unobserve(loader.current);
            }
        };
    }, [loader, currentPage, totalPages, loading]);

    return (
        <div className="orders">
            <Helmet>
                <title>订单</title>
            </Helmet>
            <div className="order-list-wrap">
                <div className="order-list">
                    {orders.map(order => (
                        <OrderItem key={order.id} order={order}/>
                    ))}
                </div>
            </div>
            <div ref={loader} className="loader">
                {loading && <p>加载中...</p>}
            </div>
        </div>
    );
}

export default Orders;
