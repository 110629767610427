import React, { useState, useEffect } from 'react';
import './Charge.css';
import { Helmet } from 'react-helmet';
import axios from "axios";
import { WX_OPEM_ID } from "../../constants";

function Charge() {

    const [chargePackages, setChargePackages] = useState([]);
    // 定义一个状态，用来存储当前选中的列表项的索引
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        fetchCharePackages();

        fetchBalance();
    }, []);

    // 点击事件处理函数
    const handleClick = (index) => {
        setSelectedIndex(index);
    };

    const btnCharge = () => {
        let chargePackage = chargePackages[selectedIndex];
        fetchChargePay(chargePackage);
    }

    const fetchBalance = async () => {
        let balance = await axios.get('/api/user/balance');
        setBalance(balance);
    }

    const fetchCharePackages = async () => {
        let chargePackages = await axios.get('/api/charge-package/list');
        setChargePackages(chargePackages);
    }

    const fetchChargePay = async (chargePackage) => {

        let openId = localStorage.getItem(WX_OPEM_ID);
        let payParams = await axios.post(`/api/user/charge/${openId}/${chargePackage.id}`);
        // 处理成功的响应
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', payParams,
            function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    fetchBalance();
                }
            });
    }

    return (
        <div id="appview">
            <Helmet>
                <title>充值</title>
            </Helmet>
            <div data-reactroot="" id="g_body">
                <div className="recharge-bg-box">
                    <div className="recharge-wrap">
                        <div className="recharge-header">
                            <div className="title">
                                <span className="icon-recharge"></span>账户余额
                            </div>
                            <div className="rest">{balance}<span>元</span>
                            </div>
                        </div>
                        <div className="recharge-body">
                            <div className="title">充值金额</div>
                            <div className="recharge-list">
                                {chargePackages.map((chargePackage, index) => (
                                    <div
                                         key={index}
                                         className={`recharge-item-wrap ${selectedIndex === index ? 'current' : ''}`}
                                         onClick={() => handleClick(index)}>
                                        <div className="recharge-item">
                                            <div className="border-box">
                                                <div className="center-box">
                                                    <div className="price">¥<strong>{chargePackage.chargeAmount}</strong>
                                                    </div>
                                                    <div className="bonus">
                                                        {chargePackage.packageName}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="btn-box">
                                <div className="btn-blue" onClick={() => btnCharge()}>立即充值</div>
                                <div className="recharge-tip">点击立即充值,即表示已经同意<span
                                    className="txt-blue">《充送活动协议》</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Charge;