// reducers.js
const initialState = {
    bookUserList: [],
    bookTime: ''
};

function rootReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case 'setBookUserList':
            return { ...state, bookUserList: payload };
        case 'setBookTime':
            return { ...state, bookTime: payload };
        default:
            return state;
    }
}

export default rootReducer;
