import React from 'react';
import {
    Outlet
} from 'react-router-dom';
import TabBar from './components/TabBar/TabBar';
import './App.css';

function App() {
    return (
        <div className="app">
            <Outlet />
            <TabBar/>
        </div>
    );
}

export default App;
