import React from 'react';
import StoreItem from '../StoreItem/StoreItem';
import './StoreList.css';

function StoreList({ stores, openDrawer}) {
  return (
    <div className="store-list">
      {stores.map(store => (
        <StoreItem key={store.id} store={store} openDrawer={openDrawer} />
      ))}
    </div>
  );
}

export default StoreList;
