import React, { useState} from "react";

import {Toast} from 'antd-mobile'

import mechAvatar from "../../static/img/user_avatar_4.jpg";

function Index({shopId, bookInfo, setShowDrawer, next}) {

    const [shopProjects, setShopProjects] = useState(bookInfo.products);
    const [attachedProducts, setAttachedProducts] = useState([]);
    const [shopMechs, setShopMechs] = useState(bookInfo.shopMechs);
    const [chooseMechIndex, setChooseMechIndex] = useState(-1);
    const [selectProjectItems, setSelectProjectItems] = useState([]);
    const [showMech, setShowMech] = useState(false);

    const closeDrawer = () => {
        setShowDrawer(false);
    };

    const chooseProject = (itemId) => {
        if (selectProjectItems.includes(itemId)) {
            setSelectProjectItems(selectProjectItems.filter(id => id !== itemId));
        } else {
            setSelectProjectItems([...selectProjectItems, itemId]);
        }
    }

    // 定义一个处理复选框变化的函数
    const handleCheckboxChange = (event) => {
        setShowMech(event.target.checked);
    };

    const chooseMech = (index) => {
        setChooseMechIndex(index);
    }

    const goBook = () => {
        let projectItemList = [];
        shopProjects.forEach((item) => {
            if (selectProjectItems.indexOf(item.id) >= 0) {
                projectItemList.push(item);
            }
        });

        if (projectItemList.length === 0) {
            Toast.show({
                content: '必须选一个项目',
                maskStyle: {zIndex: 1301}
            });
            return;
        }
        next(projectItemList, []);
        setShowDrawer(false);
    }

    return (
        <div className="pop-wrap">
            <div className="g-mask" onClick={() => closeDrawer()}></div>
            <div className="pop-drawer">
                <div className="pop-title">
                    <div className="name-box"><span className="pre"></span><span
                        className="name">选择服务项目</span><span className="after"></span></div>
                    <div className="btn-close" onClick={() => closeDrawer()}></div>
                </div>
                <div className="pop-content">
                    <div className="pop-content-scroll-wrap">
                        <div className="line-list pop-item-list">
                            {shopProjects.map((item, index) => (
                                <div
                                    className="row"
                                    onClick={() => chooseProject(item.id)}
                                >
                                    <div className="icon-box">
                                        <span
                                            className={`icon-checkbox ${selectProjectItems.includes(item.id) ? 'icon-checkbox-checked' : ''}`}></span>
                                    </div>
                                    <div className="item-name">{item.name}</div>

                                    <div className="item-price"><span
                                        className="txt-red">¥{item.sellPrice}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="line-list pop-item-list">
                            <div className="list-title">附加项目(可选,选好常规项目才能添加哦)</div>
                            {attachedProducts.map((item, index) => (
                                <div className="row">
                                    <div className="icon-box"><span className="icon-checkbox"></span>
                                    </div>
                                    <div className="item-name">{item.name}</div>
                                    <div className="item-price"><span
                                        className="txt-red">¥{item.sellPrice}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div
                            className="line-list pop-item-list"
                        >
                            <div className="list-title">
                                指定技师(若不指定,我们将在您到店后安排)
                                <label className="check-lable">
                                    <input
                                        type="checkbox"
                                        className="mui-switch mui-switch-animbg"
                                        value="on"
                                        checked={showMech}
                                        onChange={handleCheckboxChange}
                                    />
                                </label>
                            </div>
                            <div className="row" style={{display: showMech ? 'block' : 'none'}}>
                                <div className="x-scroll-list tech-list">
                                    {shopMechs.map((item, index) => (
                                        <div
                                            onClick={() => chooseMech(index)}
                                            className={`scroll-item ${chooseMechIndex === index ? 'current' : ''}`}>
                                            <div className="tech-pic">
                                                <img alt="" src={mechAvatar}/>
                                            </div>
                                            <div className="item-text">
                                                {item.mechNo}-{item.stageName}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pop-footer">
                    <div className="pop-btn-footer-box" onClick={() => goBook()}>
                        <div className="btn-red">下一步（选择人数）</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;