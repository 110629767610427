import React from 'react';
import './OrderItem.css';
import axios from "axios";
import { Dialog, Toast } from 'antd-mobile'

function OrderItem({order}) {

    const [cancelFlag, setCancelFlag] = React.useState(order.cancelFlag);

    const cancelOrder = async (orderId) => {

        const result = await Dialog.confirm({
            content: '确认取消订单?',
        });

        if (result) {
            const response = await axios.post(`/api/order/cancel?orderId=${orderId}`)
            if (response === 'OK') {
                Toast.show({
                    content: '取消成功',
                    maskStyle: {zIndex: 1300}
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        }

    }

    return (
        <div className="order-item">
            <div className="header">
                <div className="ico-box"><span className="icon-shop"></span></div>
                <div className="shop-name">{order.shopName}</div>
                <div className="order-status txt-gray">{order.statusName}</div>
            </div>
            <div className="body">
                <div className="item">
                    <div className="ico-box"><span className="icon-alarm"></span></div>
                    <div className="content-box">{order.bookTimeDisplay}</div>
                </div>
                <div className="item">
                    <div className="ico-box"><span className="icon-project"></span></div>
                    <div className="content-box">
                        <div className="order-detail-list">
                            {order.products.map((item, index) =>
                                <div className="detail-row">
                                    <div className="item-info">{item.productName}</div>
                                    <div className="item-side-info"><span
                                        className="txt-red">¥{item.productPrice}</span><span
                                        className="txt-gray"> x 1</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="ico-box"><span className="icon-total"></span></div>
                <div className="price-info"><span
                    className="txt-red">¥{order.orderPrice}</span>
                </div>

                {cancelFlag ? <div className="btn-box" onClick={() => cancelOrder(order.id)}>
                    <div className="btn-blue-circle">取消订单</div>
                </div> : null
                }

            </div>
        </div>
    );
}

export default OrderItem;
