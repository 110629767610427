import axios from 'axios';
import { getItem } from "./localstore";
import { X_TOKEN } from "./constants";

import {Toast} from 'antd-mobile'

// 添加请求拦截器（如果需要，可以在这里添加）
axios.interceptors.request.use(
    config => {
        // 在请求发送之前做些什么

        let token = getItem(X_TOKEN);
        if (token) {
            config.headers[X_TOKEN] = token;
        }

        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
axios.interceptors.response.use(
    response => {
        // 对响应数据做些什么

        if (response.data.code === 0) {
            return response.data.data;
        } else {
            Toast.show({
                content: response.data.msg,
                maskStyle: {zIndex: 1300}
            });

            // eslint-disable-next-line no-throw-literal
            throw {
                code: response.data.code,
                msg: response.data.message,
            }
        }
    },
    error => {
        const { response } = error;
        if (response && response.status === 401) {
            // 如果状态码是 401，表示未登录
            // 跳转到登录页面
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);
