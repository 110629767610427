import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {useEffect, useState} from "react";

function Index({setShowPayPop}) {

    const navigate = useNavigate();
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        fetchBalance();
    }, []);

    const goCharge = () => {
        navigate('/charge');
    }

    const goBookResult = () => {
        navigate('/book-result');
    }

    const fetchBalance = async () => {
        try {
            const response = await axios.get('/api/user/balance');
            const ajaxResult = response.data;
            setBalance(ajaxResult.data);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }

    return (
        <div className="pop-wrap">
            <div className="g-mask" onClick={() => setShowPayPop(false)}></div>
            <div className="pop-drawer">
                <div className="pop-title">
                    <div className="name-box"><span className="pre"></span><span className="name">支付方式</span><span
                        className="after"></span></div>
                    <div className="btn-close" onClick={() => setShowPayPop(false)}></div>
                </div>
                <div className="pop-content">
                    <div className="line-list pay-way-list">
                        <div className="row pay-info-title">
                            <div
                                className="pay-price">¥130</div>
                        </div>
                        <div className="row">
                            <div className="icon-box"><span className="icon-balance"></span></div>
                            <div className="content-box">
                                <div className="rest-info"><span className="icon-radio"></span>我的余额:
                                    ¥ {balance}<span
                                        className="txt-red">余额不足</span></div>
                                <div className="recharge-box" onClick={() => goCharge()}>
                                    <div className="txt-red">
                                        <span
                                        className="deposite-rule">充500送30，充1000送100 可省7.35元，去充值&gt;</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="icon-box"><span className="icon-wechat"></span></div>
                            <div className="content-box">
                                <div className="rest-info"><span className="pay-text">微信支付</span><span
                                    className="icon-radio-checked"></span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pop-footer" onClick={() => goBookResult()}>
                    <div className="pop-btn-footer-box">
                        <div className="btn-red">确定支付</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;