import React from 'react';
import { NavLink } from 'react-router-dom';
import './TabBar.css';

function TabBar() {
  return (
    <div className="tab-bar">
      <NavLink to="/appoint" activeClassName="active">预约</NavLink>
      <NavLink to="/orders" activeClassName="active">订单</NavLink>
      <NavLink to="/profile" activeClassName="active">我的</NavLink>
    </div>
  );
}

export default TabBar;
