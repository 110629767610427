import React from 'react';
import './MyCoupon.less'

function MyCoupon() {
    return (
        <div>
            Hello MyCoupon!
        </div>
    );
}

export default MyCoupon;