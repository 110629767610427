function BookResult() {
    return (
        <div id="appview">
            <div data-reactroot="" id="g_body">
                <div className="book-result-wrap">
                    <div className="status-title"><span className="icon-success"></span>恭喜您,预约成功!
                    </div>
                    <div className="section">
                        <div className="pay-info">待到店支付：<span
                            className="txt-red">¥158</span>
                        </div>
                        <div className="book-result-list">
                            <div className="row">
                                <div className="row-title">门&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;店:</div>
                                <div className="row-content">小确幸 Massage(人民广场店)</div>
                            </div>
                            <div className="row">
                                <div className="row-title">地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址:</div>
                                <div className="row-content">上海市黄浦区金陵东路569号汇通大厦908A
                                </div>
                            </div>
                            <div className="row">
                                <div className="row-title">到店时间:</div>
                                <div className="row-content">2024/08/03 10:15 周六</div>
                            </div>
                            <div className="row">
                                <div className="row-title">预约人数:</div>
                                <div className="row-content">1</div>
                            </div>
                        </div>
                        <div className="btn-box">
                            <div className="btn-blue-circle">查看我的订单</div>
                        </div>
                    </div>
                    <div className="tips-section">
                        <div className="row">提示:</div>
                        <div className="row">-为节省时间，您可以查看订单，随时进行支付买单</div>
                        <div className="row">-为保证您服务正常进行，请按时到店</div>
                        <div className="row">-如果有改动，请及时联系客服</div>
                    </div>
                </div>
                </div>
        </div>
    );
}

export default BookResult;