import React from 'react';
import './OrderDetail.scss'

function OrderDetail() {
    return (
        <div id="appview">
            <div data-reactroot="" id="g_body">
                <div className="order-detail-wrap">
                    <div className="page-body">
                        <div className="order-status-bar gray">已取消<span
                            className="status-tip"></span></div>

                        <div className="order-detail-section order-process"></div>
                        <div className="order-detail-section order-shop-info">
                            <div className="line-list order-list">
                                <div className="row">
                                    <div className="title">服务地址</div>
                                </div>
                                <div className="row">
                                    <div className="shop-detail-info">
                                        <div className="left">
                                            <div className="shop-name">小确幸 Massage(人民广场店)</div>
                                            <div className="shop-address">
                                                <span className="icon-position"></span>
                                                <span className="address-txt">上海市黄浦区金陵东路569号汇通大厦908A</span>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="border"></div>
                                            <span className="icon-phone"></span>
                                            <a className="tel-btn" href="tel:18616529707"></a></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="txt-blue">门店详细位置示意图</div>
                                    <span className="icon-arrow-right"></span></div>
                            </div>
                        </div>
                        <div>
                            <div className="order-detail-section order-item-info">
                                <div className="line-list order-list">
                                    <div className="row">
                                        <div className="title">预约项目</div>
                                    </div>
                                    <div className="row">
                                        <div className="order-item-detail">
                                            <div className="icon-box"><span className="icon-people"></span></div>
                                            <div className="content-box">
                                                <div className="top"><span
                                                    className="txt-blue">预约人1 |</span><span
                                                    className="txt-gray">到店安排</span></div>
                                                <div className="bottom">
                                                    <div className="order-single-item"><span
                                                        className="item-name">单人︱小确幸精油SPA（90分钟）</span><span
                                                        className="item-price">¥158</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="separate-row">
                                            <div
                                                className="left">项目共: 1项
                                            </div>
                                            <div className="right">合计金额:<span
                                                className="txt-red"> ¥158</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="separate-row">
                                            <div className="left"></div>
                                            <div
                                                className="right">待支付：<strong
                                                className="txt-red">¥158</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="order-detail-section">
                                <div className="row">
                                    <div className="order-brief-info">
                                        <div
                                            className="brief-item">预约号码: 18583754887
                                        </div>
                                        <div
                                            className="brief-item">预约人数: 1人
                                        </div>
                                        <div
                                            className="brief-item">预约时间: 2024/08/03
                                            10:15 周六
                                        </div>
                                        <div
                                            className="brief-item">备注信息:
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="order-brief-info">
                                        <div
                                            className="brief-item">订单编号: 17226098138983349
                                        </div>
                                        <div
                                            className="brief-item">下单时间: 2024/08/02
                                            22:43
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-footer">
                        <div className="page-footer-btn-box">
                            <div className="btn-blue-circle order-more">再来一单</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default OrderDetail;