import React, {useEffect, useState} from 'react';
import './Profile.css';
import {Helmet} from "react-helmet";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {getItem} from "../../localstore";
import {X_TOKEN} from "../../constants";
import { Dialog, Toast } from 'antd-mobile'
import {clearItem} from "../../localstore";

function Profile() {

  const navigate = useNavigate();
  const [balance, setBalance] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [isLogin, setLogin] = useState(false);

  useEffect(() => {

    let token = getItem(X_TOKEN);
    if (token) {
      fetchBalance();
      fetchUserInfo();
      setLogin(true);
    }

  }, []);

  // 点击事件处理函数
  const goLogin = () => {
    navigate('/login');
  };

  const goCharge = () => {
    navigate('/charge');
  };

  const goOrders = () => {
    navigate('/orders');
  };

  const goCoupon = () => {
    navigate('/myCoupon');
  };

  const goTel = () => {
    // 创建一个包含tel链接的锚点
    const phoneNumber = '13761006743'; // 替换为你想拨打的电话号码
    window.location.href = `tel:${phoneNumber}`;
  };

  const logout = async () => {
    const result = await Dialog.confirm({
      content: '确认退出登录?',
    });

    if (result) {
      clearItem(X_TOKEN);
      Toast.show({ content: '退出成功', position: 'bottom' });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  const fetchBalance = async () => {
    let balance = await axios.get('/api/user/balance');
    setBalance(balance);
  };

  const fetchUserInfo = async () => {
    let userInfo = await axios.get('/api/userInfo');
    setUserInfo(userInfo);
  }

  return (
      <div className="profile">
        <Helmet>
          <title>我的</title>
        </Helmet>
        <div className="user-info-wrap">
          <div className="header-box">
            <div className="user-detail-info">
              <div className="user-avatar">
                <div className="img-box">
                  <img src="https://shenghe-static.oss-cn-shanghai.aliyuncs.com/static_default_avatar.png"/>
                </div>
              </div>
              {!isLogin ?
                  <div className="btn-blue-circle" onClick={() => goLogin()}>
                请登录
                  </div> :
                  <div>{userInfo.mobile}</div>
              }
            </div>
            <div className="account-info">
              <div className="title"><span className="icon-account"></span><span className="title-text">账户余额</span>
              </div>
              <div className="content">
                <div className="rest-info-box"><span className="price">{balance}</span><span className="small">元</span></div>
                <div
                    className="recharge-info" onClick={() => goCharge()}>充500送30，充1000送100 去充值&gt;</div>
              </div>
            </div>
          </div>
          <div className="body-box">
            <div className="user-info-section">
              <div className="list-item"><span className="icon-allorder"></span>我的订单
                <div className="side-info" onClick={() => goOrders()}>查看全部<span
                    className="icon-arrow-right"></span></div>
              </div>
              <div className="order-type">
                <div className="item" onClick={() => goOrders()}>
                  <div className="item-box">
                    <div className="top"><span className="icon-unconsumed"></span>
                    </div>
                    <div className="bottom">待消费</div>
                  </div>
                </div>
                <div className="item" onClick={() => goOrders()}>
                  <div className="item-box">
                    <div className="top"><span className="icon-service"></span></div>
                    <div className="bottom">服务中</div>
                  </div>
                </div>
                <div className="item" onClick={() => goOrders()}>
                  <div className="item-box">
                    <div className="top"><span className="icon-completed"></span></div>
                    <div className="bottom">已完成</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="user-info-section">
              <div className="list-item" onClick={() => goTel()}>
                <span className="icon-join"></span>联系客服
                <div className="side-info"><span className="icon-arrow-right"></span></div>
              </div>
              <div className="list-item" onClick={() => logout()}>
                <span className="icon-logout"></span>退出登录
                <div className="side-info"><span className="icon-arrow-right"></span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Profile;
