import React from 'react';
import './StoreItem.css';

function StoreItem({store, openDrawer}) {
    return (
        <div className="list-item">
            <div className="prod-info g-flex">
                <div className="img-block"><span className="img-lab"></span>
                    <img src="https://shenghe-static.oss-cn-shanghai.aliyuncs.com/shenghe_shop.jpg"/>
                </div>
                <div className="g-col-auto">
                    <div className="g-flex">
                        <h2 className="g-col-auto">{store.name}</h2>
                        <span className="distance">{store.distanceDisplay}</span>
                    </div>
                    <div className="add-info">
                        <span className="bg-icon_address"></span>
                        <span className="add-text g-ellips">{store.address}</span></div>
                    <div className="add-info">
                        <span className="bg-icon_order"></span>
                        <span className="add-text g-ellips">已服务：{store.ordersCount}单</span>
                    </div>
                </div>
            </div>
            <div className="js_shop_products">
                {store.latestProjects.map((item, index) =>
                    <div className="promo-info g-flex">
                        <div className="icon_box"></div>
                        <div className="g-col-auto g-flex">
                            <div className="g-col-auto">
                                {item.name}
                            </div>
                            <div className="promo-pric">
                                ¥{item.sellPrice}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="order-block">
                <div className="tel-box">
                    <span className="bg-icon_phone"></span>
                    <a className="tel-btn" href={`tel:${store.contactMobile}`}></a></div>
                <div className="btn-order" onClick={() => openDrawer(store.id)}>立即预约</div>
            </div>
        </div>
    );
}

export default StoreItem;
